// eslint-disable-next-line no-shadow
import Image from 'next/image'
import { FC } from 'react'
import styled from 'styled-components'

import { Seo } from '~/components/seo'

const ImageLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

export const PageNotFound: FC = () => {
  return (
    <>
      <Seo title="Page not found" />
      <ImageLayout>
        <Image
          alt="Page not found"
          height={569}
          layout="intrinsic"
          src="/media/404.svg"
          width={716}
        />
      </ImageLayout>
    </>
  )
}
